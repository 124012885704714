import * as React from 'react';
import { Dropdown } from 'primereact/dropdown';
import "./CustomSelect.css";
import { fontSize } from '../../../constants/styled';

const CustomSelect = ({ placeholder, filter, error, options, idx, showClear, selectedOption, postIcon, icon, disabled, classes, panelClasses, handleChange = () => { } }) => {
    const selectedOptionTemplate = (option, icon, placeholder) => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                {icon && <img src={icon} className="ml-1 h-[0.7rem] md:h-[0.9rem] lg:h-[1rem] xl:h-[1.2rem]" alt="icon" />}
                {option && <div className={`ml-3 ${fontSize[12]}`}>{option}</div>}
                {!option && placeholder && <span className={`ml-3 ${fontSize[12]}`}>{placeholder}</span>}
            </div>
        );
    };

    return (
        <div className=''>
        <Dropdown
            className={classes}
            panelClassName={panelClasses}
            value={selectedOption}
            onChange={(e) => handleChange(e.value ? e.value : "")}
            options={options}
            optionLabel="name"
            placeholder={placeholder || "Select"} filter={filter}
            valueTemplate={() => selectedOptionTemplate(selectedOption, icon, placeholder)}
            appendTo="self"
            showFilterClear
            icon={icon}
            disabled={disabled}
            showClear={showClear && selectedOption}
        />
        </div>
    )
}

export default CustomSelect;
