import React from 'react';
import { Skeleton } from 'primereact/skeleton';

const SummarySkeleton = () => {
    return (
        <div className="w-[100%] shadow-lg border-1 border-[#EEE] p-3 lg:p-5 font-[600] space-y-4 bg-white">
            <div className="space-y-2">
                <Skeleton width="95%" height="3rem" className="mb-2"></Skeleton>
                <Skeleton width="95%" height="3rem" className="mb-2"></Skeleton>
                <Skeleton width="95%" height="3rem" className="mb-2"></Skeleton>
                <Skeleton width="95%" height="3rem" className="mb-2"></Skeleton>
                <Skeleton width="95%" height="3rem" className="mb-2"></Skeleton>
                <Skeleton width="95%" height="3rem" className="mb-2"></Skeleton>
            </div>
        </div>
    );
}

export default SummarySkeleton;
