import React from 'react';
// import CustomButtonWithIcon from '../Shared/CustomButtonWithIcon/CustomButtonWithIcon';
// import { customThemes } from '../../constants/app-constants';
import errScrImg from "../../resources/networkError.png"

const OfflinePage = ({ isOnline, onClick = () => { } }) => {
    return <div style={{ width: "100vw", height: "100vh", background: "#F0F4F7", display: "flex", flexDirection: "column", rowGap: "40px", justifyContent: "center", alignItems: "center", position: "fixed", top: 0, left: 0, right: 0, visibility: isOnline ? "hidden" : "visible", zIndex: 9999999999 }} >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "25px" }} >
            <div style={{ width: "150px", height: "150px" }} >
                <img style={{ width: "100%", height: "100%" }} src={errScrImg} alt="network-error" />
            </div>
            <div>
                <div style={{ fontSize: "36px", fontWeight: "700", lineHeight: "52px", color: "#404040" }}>
                    Please wait - Do not refresh page
                </div>
                <div style={{ fontSize: "16px", fontWeight: "400", lineHeight: "22px", color: "#626262" }}>
                    If refreshed - it will lose the previous progress on the page.
                </div>
                <div style={{ fontSize: "16px", fontWeight: "400", lineHeight: "22px", color: "#626262" }}>
                    Please wait to pick up from where you left off.
                </div>
            </div>
        </div>
        {/* <div style={{ display: "flex", width: "22.5rem" }} >
            <CustomButtonWithIcon label="Please wait" theme={customThemes.primary} clicked={onClick} />
        </div> */}
    </div>
}

export default OfflinePage;
