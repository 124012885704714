import React from "react";
import "./CustomTimePicker.css";
import 'rsuite/dist/rsuite-no-reset.min.css';
// import icon from '../../../resources/dateIcon.png'
import { DatePicker } from 'rsuite';

const CustomTimePicker = ({ date, minDate, type, maxDate, classes, iconClass, setDate = () => { } }) => {
  const handleDateChange = (date) => {
    setDate(date, type);
  };
  // const disabledDate = (date) => {
  //   const date1 = minDate || date;
  //   const date2 = maxDate || date;
  //   const year1 = date1.getFullYear();
  //   const month1 = date1.getMonth();
  //   const day1 = date1.getDate();
  //   const year2 = date2.getFullYear();
  //   const month2 = date2.getMonth();
  //   const day2 = date2.getDate();
  //   return (year1 < year2 || (year1 === year2 && month1 < month2) || (year1 === year2 && month1 === month2 && day1 < day2))
  // };
  const getContainer = () => {
    if (type === "fromTime") {
      return document.querySelector('.rs-picker-fromtime');
    } else if (type === "toTime") {
      return document.querySelector('.rs-picker-totime');
    }
    return null;
  };
  const pickerClass = type === "fromTime" ? "rs-picker-fromtime" : "rs-picker-totime";
  return (
    <div className="flex items-center bg-white px-2 border-1 border-white rounded-[8px] custom-group-multiselect">
      <div>
        <i className={`pi ${iconClass}`} />
      </div>
      <DatePicker
        value={date}
        onChange={handleDateChange}
        format="HH:mm"
        // caretAs={FaClock}
        placeholder="Select a time"
        // disabledDate={disabledDate}
        // block
        // oneTap
        cleanable={false}
        showMeridian
        container={getContainer}
        editable={true}
        className={`${classes || "w-full"} ${pickerClass}`}
      />
    </div>
  )
}

export default CustomTimePicker;
