import { configureStore } from "@reduxjs/toolkit";
import authSliceReducer from "./authSlices";
import filterSliceReducer from "./filterSlices";
import paginationSliceReducer from "./paginationSlices";
import eventsSliceReducer from "./eventSlices";
import notificationReducer from "./notificationSlices";

const store = configureStore({
    reducer: {
        auth: authSliceReducer,
        filter: filterSliceReducer,
        pagination: paginationSliceReducer,
        events: eventsSliceReducer,
        notification: notificationReducer,
    }
});

export default store;
