import { useSelector } from 'react-redux';
import { routes } from '../constants/routings';

const useReduxSearch = (store) => {
  const auth = useSelector(state => state.auth);
  const fixedFilter = useSelector(state => state.filter.filters);
  const selectedFilters = useSelector(state => state.filter.selectedFilters);
  const pagination = useSelector(state => state.pagination);
  const notification = useSelector(state => state.notification);
  const eventData = useSelector(state => state.events)

  switch (store) {
    case routes.auth.key:
      return auth;
    case routes.filters.key:
      return fixedFilter;
    case routes.selectedFilters.key:
      return selectedFilters;
    case routes.pagination.key:
      return pagination
    case routes.notification.key:
      return notification
    case routes[store].key:
      return eventData[routes[store].key];
    case routes.eventCounts.key:
      return eventData.eventCounts
    default:
      return undefined;
  }
};

export default useReduxSearch;
