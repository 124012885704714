import React from 'react';
import './SourceBlock.css'
import { useDispatch } from 'react-redux';
// import { blockSource, getBlockedSources as getBlockedSourcesList, unblockSource } from '../../actions/global';
// import successImg from '../../resources/successLogo.png'
// import undoImg from '../../resources/returnIcon.png'
import { Checkbox } from "primereact/checkbox";
import BlockedSourcesList from './BlockedSourcesList';
import CustomButton from '../shared/CustomButton/CustomButton';
import { fontSize } from '../../constants/styled';
import successImg from '../../resources/successLogo.png';
import undoImg2 from '../../resources/returnLogo.png';
import { blockSource, getBlockedSources as getBlockedSourcesList, unblockSource } from '../../utils/apiCalls';
import CustomDialogBox from '../shared/CustomDialogBox/CustomDialogBox';
import useReduxSearch from '../../hooks/useReduxSearch';
import { routes } from '../../constants/routings';
import { getEventCards, getEventCount } from '../../store/eventActions';

const DefaultDialogContent = ({ headerIcon, headerText, bodyText, buttonIcon, buttonText, footer, handleButtonAction = () => { } }) => {
    return <>
        <div className="bg-[#D4EAF0] p-[2rem] w-[35rem] max-w-[35rem] rounded-[12px]">
            <div className='w-full flex flex-col items-center'>
                <div className="flex space-x-4 items-center">
                    <img className='h-[2.5rem]' src={headerIcon} alt="img-with-header" />
                    <div className={`${fontSize[24]} text-[#0038FF] underline`} >
                        <p>{headerText}</p>
                    </div>
                </div>
                {bodyText && <div className={`${fontSize[20]} text-text-color`}>
                    <p>{bodyText}</p>
                </div>}
                {buttonText && <div>
                    <div onClick={handleButtonAction} className="flex items-center space-x-2 border-1 rounded-[20px] border-gray px-4 py-2 mt-4 hover:cursor-pointer" >
                        {buttonIcon && <img className='h-[1.5rem]' src={buttonIcon} alt="pre-button-icon" />}
                        <p className={`${fontSize[16]}`}>{buttonText}</p>
                    </div>
                </div>}
                {footer && <div>{footer}</div>}
            </div>
        </div>
    </>
}

const Tooltip = ({ checkbox }) => {
    const [checked, setChecked] = React.useState(checkbox)
    const handleCheckboxClick = (e) => {
        setChecked(e.checked)
        if (e.checked) {
            localStorage.setItem("YmxvY2stc291cmNlLWNoZWNrYm94LXRpY2s", true)
        }
    }
    if (checkbox) return <></>
    return <>
        <div className='source-rejection-tooltip-dialog'>
            <div className='flex'>
                <div className='w-full'></div>
                <div className='flex flex-col items-center'>
                    <div>
                        <CustomButton
                            fontSize={fontSize[16]}
                            icon={{ pre: true, class: "pi-ban font-[700] text-[#414C5B]" }}
                            background="h-[100%] p-3 bg-[#D4EAF0]"
                            border="border-1 border-[#414C5B] rounded-[10px]"
                            hover="hover:cursor-pointer"
                        />
                    </div>
                    <div className='w-[2px] bg-white h-[7rem]'></div>
                </div>
            </div>
            <div className='flex'>
                <div style={{ background: "#FFFFFD", borderRadius: "8px", padding: "1rem 1.5rem" }} >
                    <div style={{ color: "#413F40", fontSize: "16px", marginBottom: "5px" }}>Click here to un-block sources</div>
                    <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
                        <div >
                            <Checkbox className='border-blue border-1 rounded-[4px]' onChange={handleCheckboxClick} checked={checked}></Checkbox>
                        </div>
                        <div style={{ color: "#979797", fontSize: "12px" }}>Don't show again</div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const SourceBlock = ({ currentPath, aiTranslationFlag, isPostAction, isShowList, eventUrl, eventId }) => {
    const dispatch = useDispatch();
    const [sourceRejectionScreen, setSourceRejectionScreen] = React.useState(1)
    const [showDialog, setShowDialog] = React.useState({
        showRejectedSourceList: false,
        showPostRejectionClick: false,
        url: "",
        eventId: ""
      })
    const [blockedSource, setBlockedSources] = React.useState({
        isFetching: false,
        data: [],
    })
    const token = useReduxSearch(routes.auth.key).authToken
    const selectedFilterData = useReduxSearch(routes.selectedFilters.key);
    const pagination = useReduxSearch(routes.pagination.key)[routes.extracted.key]
    const tooltipCheckbox = localStorage.getItem("YmxvY2stc291cmNlLWNoZWNrYm94LXRpY2s") !== null
    const apiRequestParamsFilter = {
        eventType: routes.extracted.key,
        fromDate: selectedFilterData.fromDate,
        toDate: selectedFilterData.toDate,
        disease: selectedFilterData.disease,
        state: selectedFilterData.state,
        district: selectedFilterData.district,
      }

    const handleRejectedSourcesListClose = () => {
        // api call
        setSourceRejectionScreen(1)
        setShowDialog(prev => ({
            ...prev,
            showRejectedSourceList: !prev.showRejectedSourceList,
            url: "",
            eventId: ""
        }))
    }

    const handleRejectedSourcesListOpen = () => {
        if (isPostAction) {
            setShowDialog(prev => ({
                ...prev,
                showPostRejectionClick: true
            }))
            const timeout = setTimeout(() => {
                setSourceRejectionScreen(2)
            }, 2000);
            const timeout2 = setTimeout(() => {
                // handleAction("custom url blocking", showDialog.url)
                handlePostSourceRejectionClose()
            }, 5000);
            return () => {
                clearTimeout(timeout)
                clearTimeout(timeout2)
                setSourceRejectionScreen(1)
            }
        }
        if (isShowList) {
            setShowDialog(prev => ({
                ...prev,
                showRejectedSourceList: true,
            }))
            getBlockedSources()
        }
    }

    const handlePostSourceRejectionClose = () => {
        // api call
        handleBlockClick(eventUrl, eventId)
        setSourceRejectionScreen(1)
        setShowDialog(prev => ({
            ...prev,
            showPostRejectionClick: false,
            showRejectedSourceList: false,
            url: "",
            eventId: ""
        }))
    }

    const getBlockedSources = () => {
        setBlockedSources(prev => ({ ...prev, isFetching: true }))
        getBlockedSourcesList(token).then((res) => {
            if (res?.sources) {
                const formattedArray = [];
                res?.sources.forEach(item => {
                    const existingGroup = formattedArray.find(group => group.date === item.blocked_date);
                    if (existingGroup) {
                        existingGroup.items.push(item);
                    } else {
                        formattedArray.push({
                            date: item.blocked_date,
                            items: [item]
                        });
                    }
                });
                setBlockedSources({
                    isFetching: false,
                    data: formattedArray
                })
            }
        })
    }

    const handleBlockClick = (url, eventId) => {
        const jsonObj = {
            source_name: url,
            event_id: eventId
        }
        blockSource(jsonObj, token).then((res) => {
            if (res) {
                // Call event and count api
                dispatch(getEventCount(apiRequestParamsFilter, token, "action"))
                if (currentPath === routes.extracted.key) {
                    apiRequestParamsFilter["currentPage"] = pagination.currentPage
                    dispatch(getEventCards(apiRequestParamsFilter, token))
                }
            }
        })
    }

    const handleUnblockClick = (tempObj) => {
        setShowDialog(prev => ({
            ...prev,
            url: tempObj.source_name
        }))
        unblockSource(tempObj, token).then((res) => {
            if (res) {
                setSourceRejectionScreen(2)
                // Call event and count api
                dispatch(getEventCount(apiRequestParamsFilter, token, "action"))
                if (currentPath === routes.extracted.key) {
                    apiRequestParamsFilter["currentPage"] = pagination.currentPage
                    dispatch(getEventCards(apiRequestParamsFilter, token))
                }
            }
        })
    }

    const handleUndoAction = () => {
        setSourceRejectionScreen(1)
        setShowDialog(prev => ({
            ...prev,
            showPostRejectionClick: false,
            url: ""
        }))
    }
    React.useEffect(() => {
        let timeout = null
        if (showDialog.showRejectedSourceList && sourceRejectionScreen === 2) {
            timeout = setTimeout(() => {
                handleRejectedSourcesListClose()
            }, 5000);
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [sourceRejectionScreen])

    return <>
        <div className='h-[100%]'>
            {isShowList && <CustomButton
                fontSize={fontSize[14]}
                icon={{ pre: true, class: "pi-ban font-[700]" }}
                background="h-[100%] px-[1.5vw] lg:px-3 bg-[#EEF5FB]"
                border="border-1 border-[#C7C3C3] rounded-[10px]"
                hover="hover:cursor-pointer hover:bg-blue-light"
                action={handleRejectedSourcesListOpen}
            />}
            {isPostAction && <CustomButton
                fontSize={fontSize[10]}
                icon={{ pre: true, class: `pi-ban ${fontSize[10]} text-["#414C5B]` }}
                text={{ text: "Block Source", class: "text-[#414C5B]" }}
                background="h-[100%] px-2 whitespace-nowrap bg-[#E9E9E9]"
                border="rounded-[10px]"
                hover="hover:cursor-pointer"
                action={handleRejectedSourcesListOpen}
            />}
        </div>
        {showDialog.showRejectedSourceList && sourceRejectionScreen === 1 && <BlockedSourcesList showDialog={showDialog.showRejectedSourceList} blockedSourceData={blockedSource} aiTranslationFlag={aiTranslationFlag} token={token} handleUnblockSource={handleUnblockClick} setShowDialog={handleRejectedSourcesListClose} />}
        {showDialog.showRejectedSourceList && sourceRejectionScreen === 2 && <CustomDialogBox showDialog={showDialog.showRejectedSourceList} setShowDialog={handleRejectedSourcesListClose} >
            <DefaultDialogContent headerIcon={successImg} headerText={showDialog.url} bodyText="has been un-blocked" />
        </CustomDialogBox>}
        {showDialog.showPostRejectionClick && <CustomDialogBox showDialog={showDialog.showPostRejectionClick} setShowDialog={handlePostSourceRejectionClose}>
            <DefaultDialogContent headerIcon={successImg} headerText={eventUrl} bodyText="has been blocked" buttonIcon={undoImg2} buttonText="Undo Action" footer={sourceRejectionScreen === 2 && <Tooltip checkbox={tooltipCheckbox} />} handleButtonAction={handleUndoAction} />
        </CustomDialogBox>}
    </>
}

export default SourceBlock;
