export const SERVER_URL = process.env.REACT_APP_API_ENDPOINT;
export const loginApi = SERVER_URL + "api/auth/login";
export const diseaseCategoryApi = SERVER_URL + "api/diseases/";
export const stateCategoryApi = SERVER_URL + "api/states/";
export const districtCategoryApi = SERVER_URL + "api/districts/";
export const languageCategoriApi = SERVER_URL + "api/languages/";
export const subdistrictCategoryApi = SERVER_URL + "api/subdistricts/";
export const eventCountsApi = SERVER_URL + "api/events/count";
export const eventsApi = SERVER_URL + "api/events/";
export const eventClustersApi = SERVER_URL + "api/events/clustered_events";
export const relatedPublishedApi = SERVER_URL + "api/events/related_published";
export const eventSummaryApi = SERVER_URL + "api/events/summary";
export const eventAlertUpdatesApi = SERVER_URL + "api/events/updates";
export const eventsDeclusterApi = SERVER_URL + "api/events/decluster";
export const eventShortlistApi = SERVER_URL + "api/events/shortlist"
export const eventRejectApi = SERVER_URL + "api/events/reject"
export const eventHealthInfoApi = SERVER_URL + "api/events/health_info"
export const eventPublishApi = SERVER_URL + "api/events/publish"
export const eventRepublishApi = SERVER_URL + "api/events/republish"
export const eventReadApi = SERVER_URL + "api/events/read";
export const onboardingCompletApi = SERVER_URL + "api/onboarding_tutorial";
export const blockedSourcesApi = SERVER_URL + "api/source_rejection/"
export const blockSourceApi = SERVER_URL + "api/source_rejection/block";
export const unblockSourceApi = SERVER_URL + "api/source_rejection/unblock";
export const blockedEventApi = SERVER_URL + "api/source_rejection/event";
