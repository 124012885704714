import React from 'react';
import { Skeleton } from 'primereact/skeleton';

const CardSkeleton = () => {
    return (
        <div className="w-[100%] shadow-lg border-1 border-[#EEE] p-3 lg:p-5 font-[600] space-y-4 bg-white">
            <div className="">
                <Skeleton width="70%" height="3rem" className="mb-2"></Skeleton>
                <Skeleton width="50%" className="mb-4"></Skeleton>
            </div>
            <div className="flex w-[60%] justify-between">
                <div>
                    <h5 className="mb-1">Disease</h5>
                    <Skeleton width="20rem" height="2rem" className="mb-2" borderRadius="8px"></Skeleton>
                </div>
                <div>
                    <h5 className="mb-1">Reported deaths & cases</h5>
                    <Skeleton width="15rem" height="2rem" className="mb-2" borderRadius="8px"></Skeleton>
                </div>
            </div>
            <div className="flex w-[80%]">
                <div>
                    <h5 className="mb-1">Location State</h5>
                    <Skeleton width="15rem" height="2rem" className="mb-2" borderRadius="8px"></Skeleton>
                </div>
                <div>
                    <h5 className="mb-1">District</h5>
                    <Skeleton width="15rem" height="2rem" className="mb-2" borderRadius="8px"></Skeleton>
                </div>
                <div>
                    <h5 className="mb-1">Sub District / ULB</h5>
                    <Skeleton width="15rem" height="2rem" className="mb-2" borderRadius="8px"></Skeleton>
                </div>
            </div>
            <div className="w-[100%]">
                <h5 className="mb-1">Translated Summary</h5>
                <Skeleton width="100%" height="5rem" className="w-[100%] mb-2" borderRadius="8px"></Skeleton>
            </div>
            <div className="flex justify-between items-center mt-5">
                <div className='flex space-x-4'>
                    <Skeleton width="10rem" height="1.5rem" className="mb-2" borderRadius="8px"></Skeleton>
                    <Skeleton width="10rem" height="1.5rem" className="mb-2" borderRadius="8px"></Skeleton>
                </div>
                <div className='flex justify-end space-x-6'>
                    <Skeleton width="10rem" height="3rem" borderRadius="8px"></Skeleton>
                    <Skeleton width="10rem" height="3rem" borderRadius="8px"></Skeleton>
                </div>
            </div>
        </div>
    );
}

export default CardSkeleton;
