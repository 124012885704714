import moment from "moment";
import qs from "query-string"
import { routes } from "../constants/routings";

export const getHeaders = (token) => {
    return {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: "Bearer " + token,
    };
};

export const getParams = (data) => {
    const paramsDic = {
        from_date: moment(data.fromDate).format('yyyy-MM-DDTHH:mm'),
        to_date: moment(data.toDate).format('yyyy-MM-DDTHH:mm')
    }
    if (data["state"] && data.state.length > 0) {
        paramsDic.state = data.state
    }
    if (data["district"] && data.district.length > 0) {
        paramsDic.district = data.district
    }
    if (data["disease"] && data.disease.length > 0) {
        paramsDic.disease = data.disease
    }
    if (data["eventType"]) {
        paramsDic.event_type = data.eventType;
        if (data["eventType"] === routes.published.key && data["subTypes"] && data["subTypes"].length > 0) {
            paramsDic.event_sub_type = data.subTypes
        }
    }
    return paramsDic;
};

const fetchApi = async (url, method = "GET", body = {}, headers = new Headers(), params = {}) => {
    const obj = { method, headers, body };

    if (method === 'DELETE') {
        url = qs.stringifyUrl({ url, query: body });
        delete obj.body;
    }

    if (method === 'GET') {
        delete obj.body;
    }

    if (JSON.stringify(params) !== JSON.stringify({})) {
        const data = params;
        let newURLSearchParams = url + "?";

        for (const k in data) {
            newURLSearchParams = newURLSearchParams + encodeURIComponent(k) + "=" + encodeURIComponent(data[k]) + "&";
        }

        newURLSearchParams = newURLSearchParams.slice(0, -1);
        try {
            const response = await fetch(newURLSearchParams, obj);
            if (!response.ok) {
                throw Error('fail to fetch');
            }
            const jsondata = await response.json();
            return jsondata;
        } catch (e) {
            return false
            // console.error("error", e);
        }
    }

    try {
        const response = await fetch(url, obj)
        if (!response.ok) {
            return false
            // throw Error(`fail to fetch`);
        }
        const jsondata = await response.json();
        return jsondata;
    } catch (e) {
        return false
        // console.error("error", e);
        // throw Error(`fail to fetch ${e}`);
    }
};

export default fetchApi;
