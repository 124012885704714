import React from "react";
import CustomDialogBox from "../../shared/CustomDialogBox/CustomDialogBox";
import { fontSize } from "../../../constants/styled";
import { getClusterEventsData, getRelatedPublishedEventsData } from "../../../utils/apiCalls";
import Card from "../../Card/Card";
import CardSkeleton from "../../Card/Components/Skeleton/CardSkeleton";
import { routes } from "../../../constants/routings";
import { notificationActions } from "../../../store/notificationSlices";
import { useDispatch } from "react-redux";

const getDialogTitle = (eventData, relatedType) => {
    return relatedType === "cluster" ? `${eventData.disease} found in ${eventData.district ? eventData.district + " of " : ""} ${eventData.state}: ` : eventData?.translated_article?.title
}

const getRelatedCountAndText = (count) => {
    return `Report${count > 2 ? 's' : ''} Found in ${count - 1} Source${count > 2 ? 's' : ''}`
}

const RelatedCards = ({ relatedType, currentPath, showClusters, eventData, filterData, mainPage, authToken, setShowClusters = () => { } }) => {
    const [events, setEvents] = React.useState({ isFetching: true, events: null })
    const dispatch = useDispatch()
    let rendered = true

    const showActionErrorNotification = (message) => {
        dispatch(notificationActions.resetNotification());
        setTimeout(() => {
            dispatch(notificationActions.setNoification({
                open: true,
                message
            }));
        }, 100);
    }

    const handleApiCall = async (params, authToken) => {
        let clusterEvents = null;
        if (relatedType === "cluster") {
            clusterEvents = await getClusterEventsData(params, authToken)
        } else if (relatedType === "published") {
            clusterEvents = await getRelatedPublishedEventsData(eventData.id, eventData.parent_id, authToken)
        }
        if (clusterEvents && currentPath === routes.published.key) {
            clusterEvents = clusterEvents.map(article => ({
                ...article,
                outbreak_status: eventData.outbreak_status
            }));
        }
        if (!clusterEvents) {
            showActionErrorNotification(`Failed to get ${relatedType == "cluster" ? "Related" : relatedType == "published" ? "Related Published" : ""} events`)
        } else {
            setEvents((prev) => ({ isFetching: false, events: clusterEvents }))
        }
    }
    React.useEffect(() => {
        if (rendered) {
            setEvents((prev) => ({ isFetching: true, ...prev }))
            const params = {
                eventType: filterData.eventType,
                fromDate: filterData.fromDate,
                toDate: filterData.toDate,
                disease: filterData.disease,
                state: filterData.state,
                eventId: eventData.id,
                clusterId: eventData.cluster_id
            }
            handleApiCall(params, authToken)
            rendered = false
        }
    }, [])
    return <>
        <CustomDialogBox
            showDialog={showClusters}
            classes="w-4/5"
            clickOutsideClose={true}
            header={{
                text: getDialogTitle(eventData, relatedType),
                classes: `bg-[#D8E9EF] text-[#413F40] font-[600] ${fontSize[24]} p-2 rounded-4`,
                extra: {
                    text: `${relatedType === "cluster" ? getRelatedCountAndText(eventData.cluster_count) : ""}`,
                    classes: `text-[#3A8097] pl-3 font-[500] ${fontSize[16]}`
                }
            }}
            setShowDialog={setShowClusters}
        >
            <div className="px-4 py-2 md:py-3 lg:px-5 xl:px-6 mb-6">
                {!events.isFetching ? events.events.map((event, idx) => (
                    <div key={event.id}>
                        <Card idx={idx + 1} currentPath={filterData.eventType} eventCard={event} totalEvents={events?.events?.length} currentPage={1} mainPage={mainPage} isClusters={true} isRelatedPublished={true} />
                    </div>
                ))
                : <CardSkeleton />}
            </div>
        </CustomDialogBox>
    </>
}

export default RelatedCards;
