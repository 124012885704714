import React from "react";
import { MultiSelect } from 'primereact/multiselect';
import "./CustomGroupMultiSelect.css";

const CustomGroupMultiSelect = ({ placeholder, selectedOptions, options, icon, filter, showClear, classes, selectAllPlaceholder, handleSelection = () => { } }) => {
    const groupedItemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.label}</div>
            </div>
        );
    };
    function getSelectedItemLabel () {
        const isContainEmptyValue = selectedOptions.indexOf("") !== -1;
        const finalSelectedItemLabel = (isContainEmptyValue ? selectedOptions.length - 1 : selectedOptions.length) + " " + selectAllPlaceholder + " selected";
        return finalSelectedItemLabel;
    }
    return (
        <div className={`w-full border-1 border-white custom-group-multiselect ${icon && "flex items-center bg-white pl-2"} ${selectAllPlaceholder === "Diseases" ? "rounded-[8px]" : selectAllPlaceholder === "Districts" ? "rounded-e-[8px]" : ""}`}>
           {icon && <div className="">
                <img src={icon} className="h-[1.2rem]" alt="icon" />
            </div>}
            <MultiSelect
                value={selectedOptions}
                options={options}
                maxSelectedLabels={2}
                filter={filter}
                showClear={showClear}
                onChange={handleSelection}
                optionLabel="name"
                optionGroupLabel="label"
                optionGroupChildren="items"
                appendTo="self"
                selectAllLabel="Select All"
                optionGroupTemplate={groupedItemTemplate}
                selectedItemsLabel={getSelectedItemLabel()}
                placeholder={placeholder}
                className={classes || "rounded-[8px]"}
            />
        </div>
    );
}

export default CustomGroupMultiSelect;
