import React from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { PrimeReactProvider } from 'primereact/api';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import { BrowserRouter } from 'react-router-dom';
import Main from "./layouts/Main"
import Authenticate from './authenticate/Authenticate';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <PrimeReactProvider>
          <Authenticate />
          <Main />
        </PrimeReactProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
