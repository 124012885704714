import React from 'react';
import { useDispatch } from 'react-redux';
import { postLogin } from '../store/authActions';
import { authActions } from '../store/authSlices';
import useReduxSearch from '../hooks/useReduxSearch';
import { routes } from '../constants/routings';

const Authenticate = () => {
    const dispatch = useDispatch()
    const token = useReduxSearch(routes.auth.key).token
    React.useEffect(() => {
        const handleAuthMessage = (e) => {
            if (typeof e.data === "string" && e.data !== "" && e.data !== "SEND_AUTH_TO_IDSP") {
                dispatch(authActions.setToken(e.data))
            }
        };

        window.parent.postMessage("SEND_AUTH_TO_IDSP", '*');
        window.addEventListener('message', handleAuthMessage);

        return () => {
            window.removeEventListener('message', handleAuthMessage);
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (token !== "" && token !== undefined && token !== null) {
            dispatch(postLogin(token))
        }
    }, [token, dispatch]);
};

export default Authenticate;
