import React from "react";

const CustomButton = ({ fontSize, icon, text, border, background, hover, key, action = () => { }, onMouseEnter = () => { }, onMouseLeave = () => { } }) => {
    return (
      <div key={key} className={`w-[100%] flex items-center space-x-2 ${fontSize} ${border} ${background} ${hover}`} onClick={action} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {icon && icon.pre && <i className={`pi ${icon.class && icon.class}`} />}
        {text && text.text && <p className={`${text.class}`}>{text.text}</p>}
        {icon && icon.post && <i className={`pi ${icon.class && icon.class}`} />}
      </div>
    )
};

export default CustomButton;
