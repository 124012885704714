import React from 'react';
import { Skeleton } from 'primereact/skeleton';

const EventSkeleton = () => {
    return (
        <div className="w-[100%] rounded-[12px] px-5 pb-4 pt-6 space-y-4 bg-white overflow-scroll mb-5">
            <div className="space-y-2">
                <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
                <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
                <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
                <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
                <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
            </div>
        </div>
    );
}

export default EventSkeleton;
