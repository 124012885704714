import { loginApi } from "../apis/index";
import fetchApi from "../utils/fetchApi";
import { authActions } from "./authSlices";

const headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

export const postLogin = (token) => {
    const bodyValue = {
        encoded_basic: token
    }
    return async (dispatch) => {
        const response = await fetchApi(loginApi, "POST", JSON.stringify(bodyValue), headerValue, {});
        if (response?.success) {
            dispatch(authActions.login({
                isAuthenticated: response?.success,
                ihipToken: response?.data?.ihip_token,
                authToken: response?.data?.token,
                isOnboardingCompleted: response?.data?.onboarding_completed === "completed",
            }))
        }
    }
}
