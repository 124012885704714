import React from "react";
import { Tooltip, Whisper } from "rsuite";

const CustomTooltip = ({ children, text, placement }) => {
    const tooltip = (
        <Tooltip>
          {text}
        </Tooltip>
      );
    return <Whisper placement={placement || "top"} controlId="control-id-hover" trigger="hover" speaker={tooltip}>
        {children}
    </Whisper>
}

export default CustomTooltip;
