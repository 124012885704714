import React from "react";
import Extracted from "../pages/extractedEvents/Extracted";
import Shortlisted from "../pages/shortlistedEvents/Shortlisted";
import Rejected from "../pages/rejectedEvents/Rejected";
import HealthInfo from "../pages/healthInfo/HealthInfo";
import Published from "../pages/publishedEvents/Published";
import { Route, Routes } from "react-router-dom";
import { routes } from "../constants/routings";

const Routings = () => {
    return (
        <div className="ml-1">
            <Routes>
                <Route path={routes.extracted.route} element={<Extracted />} />
                <Route path={routes.shortlisted.route} element={<Shortlisted />} />
                <Route path={routes.published.route} element={<Published />} />
                <Route path={routes.healthInfo.route} element={<HealthInfo />} />
                <Route path={routes.rejected.route} element={<Rejected />} />
            </Routes>
        </div>
    )
};

export default Routings;
