import { createSlice } from '@reduxjs/toolkit';
import { routes } from '../constants/routings';

const initialState = {
    extracted: { currentPage: 0, totalPage: 0 },
    shortlisted: { currentPage: 0, totalPage: 0 },
    rejected: { currentPage: 0, totalPage: 0 },
    healthInfo: { currentPage: 0, totalPage: 0 },
    published: { currentPage: 0, totalPage: 0 },
};

const paginationSlice = createSlice({
    name: routes.pagination.reduxKey,
    initialState,
    reducers: {
        setPage: (state, actions) => {
            const { key, currentPage, totalPage } = actions.payload;
            if (state[key]) {
                if (currentPage !== undefined) {
                    state[key].currentPage = currentPage;
                }
                if (totalPage !== undefined) {
                    state[key].totalPage = totalPage;
                }
            }
        }
    }
});

export const paginationActions = paginationSlice.actions;

export default paginationSlice.reducer;
