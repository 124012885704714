import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import "./CustomToast.css";
import { notificationActions } from '../../../store/notificationSlices';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from '../../../constants/routings';

const CustomToast = () => {
    const toast = useRef(null);
    const dispatch = useDispatch();
    const notificationObj = useSelector(state => state[routes.notification.reduxKey]);

    useEffect(() => {
        if (notificationObj.open && toast.current) {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: notificationObj.message,
                life: 6000,
                onHide: () => {
                    dispatch(notificationActions.resetNotification());
                },
            });
        }
    }, [notificationObj.open, dispatch]);

    return (
        <>
            <Toast ref={toast} />
        </>
    );
};

export default CustomToast;
