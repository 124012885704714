import { createSlice } from '@reduxjs/toolkit';
import { routes } from '../constants/routings';

const initialAuthState = {
    isAuthenticated: false,
    token: "",
    ihipToken: "",
    authToken: "",
    isOnboardingCompleted: false
}

const authSlice = createSlice({
    name: routes.auth.reduxKey,
    initialState: initialAuthState,
    reducers: {
        setToken (state, actions) {
            state.token = actions.payload
        },
        login (state, actions) {
            state.isAuthenticated = actions.payload.isAuthenticated || state.isAuthenticated;
            state.token = actions.payload.token || state.token;
            state.ihipToken = actions.payload.ihipToken || state.ihipToken;
            state.authToken = actions.payload.authToken || state.authToken;
            state.isOnboardingCompleted = actions.payload.isOnboardingCompleted || state.isOnboardingCompleted
        },
        logout (state) {
            state.isAuthenticated = false;
            state.token = "";
            state.ihipToken = "";
            state.authToken = "";
        }
    }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
