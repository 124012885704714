export const fontSize = {
    10: "text-[1.2vw] md:text-[0.4rem] lg:text-[0.55rem] xl:text-[0.7rem] 2xl:text-[0.8rem]",
    12: "text-[1.5vw] md:text-[0.6rem] lg:text-[0.7rem] xl:text-[0.8rem] 2xl:text-[0.95rem]",
    14: "text-[1.65vw] md:text-[0.75rem] lg:text-[0.85rem] xl:text-[0.95rem] 2xl:text-[1.05rem]",
    16: "text-[1.8vw] md:text-[0.85rem] lg:text-[0.95rem] xl:text-[1.15rem] 2xl:text-[1.2rem]",
    18: "text-[2.0vw] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.4rem]",
    20: "text-[2.2vw] md:text-[1.0rem] lg:text-[1.1rem] xl:text-[1.25rem] 2xl:text-[1.6rem]",
    24: "text-[2.6vw] md:text-[1.2rem] lg:text-[1.4rem] xl:text-[1.6rem] 2xl:text-[1.8rem]",
};

export const headerHeight = "h-[6rem] md:h-[7rem] lg:h-[7.8rem] xl:h-[8.5rem] 2xl:h-[9.5rem]";
