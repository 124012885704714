import React from "react";
import { fontSize } from "../../../constants/styled";
import { routes } from "../../../constants/routings";
import CustomButton from "../../shared/CustomButton/CustomButton";
import moment from "moment";
import { itemsPerPage } from "../../../constants/constants";
import CustomTooltip from "../../shared/CustomTooltip/CustomTooltip";
import { eventChecked } from "../../../utils/apiCalls";
import SourceBlock from "../../SourceBlock/SourceBlock";

const publishUpdate = {
    Pending: {
        text: "Ongoing Investigation",
        iconColor: "text-[#41CAC2]"
    },
    Closed: {
        text: "Closed Event",
        iconColor: "text-[#EC4646]"
    },
    Ongoing: {
        text: "Ongoing Outbreak",
        iconColor: "text-[#41CAC2]"
    },
    Completed: {
        text: "Closed Outbreak",
        iconColor: "text-[#EC4646]"
    },
}

const CardTitle = ({ idx, eventData, currentPath, collapseCard, showCollapse, totalEvents, currentPage, disabled, authToken, setCollapseCard = () => { } }) => {
    const handleCollapseCard = () => {
        setCollapseCard(!collapseCard)
    }
    const handleSourceClick = () => {
        if (disabled) return
        window.open(eventData?.url);
        eventChecked({ event_id: eventData?.id, cluster_id: eventData?.cluster_id }, authToken)
    }
    return <>
        <div className="px-3 md:px-4 xl:px-5">
            <div className="flex justify-between">
                <div className="flex mr-4 lg:mr-6">
                    <span className={`${fontSize[24]} font-[700] landing-[1.2rem] lg:landing-[1.5rem] title-p-ellipsis hover:cursor-pointer hover:underline capitalize`} onClick={handleSourceClick}>
                        {eventData?.translated_article.title}.
                    </span>
                </div>
                <div className="flex space-x-2 h-[2rem] items-center">
                    {[routes.rejected.key, routes.healthInfo.key, routes.published.key].includes(currentPath) && <CustomButton
                        fontSize={fontSize[14]}
                        icon={{ pre: currentPath === routes.published.key, class: `pi-circle-fill ${currentPath === routes.published.key ? publishUpdate[eventData?.outbreak_status].iconColor : ""} text-[8px]` }}
                        text={{ text: `${currentPath === routes.healthInfo.key ? "Health Info" : currentPath === routes.rejected.key ? eventData["shortlist_date"] ? "Rejected after consulting" : "Rejected" : publishUpdate[eventData?.outbreak_status].text}`, class: `font-[500] ${currentPath === routes.healthInfo.key ? "text-[#008BCA]" : currentPath === routes.rejected.key ? "text-[#74082B]" : ""}` }}
                        background={`py-1 px-2 space-x-[4px] whitespace-nowrap ${currentPath === routes.healthInfo.key ? "bg-[#DEF0F5]" : currentPath === routes.rejected.key ? "bg-[#FFE8E8]" : "bg-[#F4F5F4]"}`}
                        border={`rounded-[8px] ${currentPath === routes.rejected.key && "border-1 border-[#FF7D7D]"}`}
                        // hover="hover:cursor-pointer"
                    />}
                    <p className={`${fontSize[14]} text-text-color font-[500]`}>{itemsPerPage * (currentPage - 1) + idx}/{totalEvents}</p>
                    <CustomTooltip text="Click to Copy Event Id" >
                        <div>
                            <CustomButton
                                icon={{ pre: true, class: `pi-clone ${fontSize[14]} font-[500] text-text-color` }}
                                background="px-2 pt-1"
                                hover="hover:cursor-pointer"
                                action={() => navigator.clipboard.writeText(eventData?.id)}
                            />
                        </div>
                    </CustomTooltip>
                    {showCollapse && <CustomButton
                        icon={{ pre: true, class: `${collapseCard ? "pi-angle-down" : "pi-angle-up"} ${fontSize[14]} font-[500] text-text-color` }}
                        background="p-2 bg-blue-light"
                        border="rounded-[50%]"
                        hover="hover:cursor-pointer"
                        action={handleCollapseCard}
                    />}
                </div>
            </div>
            <div className={`flex space-x-3 ${collapseCard ? "my-2 lg:my-3" : "mt-1 mb:2 lg:mt-2 lg:mb-3"} ${fontSize[12]} text-text-color`}>
                <p className="text-text-color">Article released at {moment(eventData?.article_release_datetime).format('hh:mma [on] Do MMMM, YYYY')} by</p>
                <p onClick={handleSourceClick} className="text-[#0038FF] hover:cursor-pointer underline">{(new URL(eventData?.url)).hostname}</p>
                <div>
                    {currentPath === routes.extracted.key && <SourceBlock isPostAction={true} eventId={eventData?.id} eventUrl={(new URL(eventData?.url)).hostname} currentPath={currentPath} />}
                </div>
            </div>
        </div>
    </>
}

export default CardTitle;
