/*
    routes is the mapping for all the Navigations, Routes, Pages, Redux Stores
    {
        route: route(path)
        name: Nave of the Page or Name of the Redux store
        component: Main Pages (Components)
        key: Own key name and path key and specific object stored in redux, it is the main key used in everywhere, entire project dependent on it
        reduxKey: Redux Store Name
    }
*/
export const routes = {
    // Routes and Page Components
    extracted: {
        route: "/extracted",
        name: "Extracted Events",
        key: "extracted",
        reduxKey: "events",
        eventType: "extracted",
    },
    shortlisted: {
        route: "/shortlisted",
        name: "Shortlisted",
        key: "shortlisted",
        reduxKey: "events",
        eventType: "shortlisted",
    },
    rejected: {
        route: "/rejected",
        name: "Rejected",
        key: "rejected",
        reduxKey: "events",
        eventType: "rejected",
    },
    published: {
        route: "/published",
        name: "Published",
        key: "published",
        reduxKey: "events",
        eventType: "published"
    },
    healthInfo: {
        route: "/healthInfo",
        name: "Saved Health Info",
        key: "healthInfo",
        reduxKey: "events",
        eventType: "healthInfo"
    },
    blockedSources: {
        route: "/blockedSources",
        name: "Blocked Sources",
        key: "blockedSources"
    },
    // Redux Stores
    events: {
        reduxKey: "events"
    },
    eventCounts: {
        key: "eventCounts",
        reduxKey: "events",
    },
    auth: {
        name: "Authentication",
        key: "auth",
        reduxKey: "auth"
    },
    notification: {
        name: "Error toast message",
        key: "notification",
        reduxKey: "notification",
    },
    pagination: {
        name: "Paginations",
        key: "pagination",
        reduxKey: "pagination",
    },
    filters: {
        name: "Filter Data for Filters",
        key: "filters",
        reduxKey: "filter",
    },
    selectedFilters: {
        name: "Selected Filter Data",
        key: "selectedFilters",
        reduxKey: "filter",
    },
}
