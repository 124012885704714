import React from 'react';
import dateIcon from '../../resources/dateIcon.png';
import diseaseIcon from '../../resources/diseaseIcon.png';
import locationIcon from '../../resources/locationIcon.png';
import deathIcon from '../../resources/deathNumberIcon.png';
import vectorIcon from '../../resources/caseNumberIcon.png';
import moment from "moment";

const TextField = ({ text, icon, iconHeight, iconWidth }) => {
    return <>
        <div style={{ padding: "8px 10px", display: "flex", borderRadius: "4px", background: "#F3F7F9", alignItems: "center", columnGap: "10px" }}>
            <img style={{ height: iconHeight || "14px", width: iconWidth || "" }} src={icon} alt="icon" />
            <div style={{ fontSize: "12px", lineHeight: "18px", color: "#414C5B", fontWeight: "400" }} >{text} </div>
        </div>
    </>
}

const TextFieldWithHeader = ({ heading, text, icon, iconHeight, iconWidth }) => {
    const textFieldHeadigStyle = {
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "18px",
        color: "#414C5B",
        marginBottom: "0.4rem"
    }
    return <>
        <div style={textFieldHeadigStyle} >{heading}</div>
        <div>
            <TextField icon={icon} text={text} />
        </div>
    </>
}

const BlockedEventCard = ({ eventData, aiTranslationFlag, sourceName }) => {
    console.log(eventData);
    return <>
        <div className='w-[100%]' style={{ background: "#daecf2", borderRadius: "3.5px", boxShadow: "#B1AEAE 2px 2px 6px", padding: "1rem", marginBottom: "1rem" }}>
            <div >
                <div style={{ fontSize: "calc(1.2vw + 6px)", fontWeight: "700", color: "#000", marginBottom: "0.8rem" }}>
                    {aiTranslationFlag ? eventData?.translated_title : eventData?.title}
                </div>
            </div>
            <div className='w-full'>
                <div className='flex space-x-4 w-full' >
                    <div className='w-1/4'>
                        <TextFieldWithHeader heading="Extracted Date" icon={dateIcon} text={moment(eventData?.extracted_date).format("MMM DD, yyyy")} />
                    </div>
                    <div className='w-1/4' >
                        <TextFieldWithHeader heading="Disease" icon={diseaseIcon} text={eventData?.disease} />
                    </div>
                    <div className='w-1/4'>
                        <div className='flex space-x-[1px]' >
                            <div className='w-1/2' >
                                <TextFieldWithHeader heading="Cases" icon={vectorIcon} text={eventData?.number?.case_new !== 0 ? eventData?.number?.case_new : eventData?.number?.case_total} />
                            </div>
                            <div className='w-1/2'>
                                <TextFieldWithHeader heading="Death" icon={deathIcon} text={eventData?.number?.death_new !== 0 ? eventData?.number?.death_new : eventData?.number?.death_total} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 w-full' >
                    <div className='w-1/4'>
                        <TextFieldWithHeader heading="Location: State" icon={locationIcon} text={eventData?.state} />
                    </div>
                    <div className='w-1/4'>
                        <TextFieldWithHeader heading="Location: District" icon={locationIcon} text={eventData?.district} />
                    </div>
                    <div className='w-1/4'>
                        <TextFieldWithHeader heading="Location: Sub-District/Corporation" icon={locationIcon} text={eventData?.sub_district} />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default BlockedEventCard;
