export const itemsPerPage = 10;

export const actionTypeMapOld = {
    shortlistNewEvent: 1,
    addToShortlistCluster: 2,
    publishNewEvent: 3,
    addToPublishCluster: 4,
    shortlistAsHealthInfo: 5,
    rejectFakeSource: 6,
    rejectOldNews: 7,
    rejectIrrelevant: 8,
    rejectPublished: 9,
    rejectInternationalEvent: 10,
}

export const actionTypeMap = {
    shortlist: {
        key: "shortlist",
        name: "Shortlisted",
        value: 1
    },
    remove: {
        key: "remove",
        name: "Remove"
    },
    reject: {
        types: {
            rejectFakeSource: {
                name: "Reject due to Fake Source",
                value: 6
            },
            rejectOldNews: {
                name: "Reject due to Old News",
                value: 7
            },
            rejectIrrelevant: {
                name: "Reject due to Irrelevant News",
                value: 8
            },
            rejectPublished: {
                name: "Reject due to already Published",
                value: 9
            },
            rejectInternationalEvent: {
                name: "Reject due to International event",
                value: 10
            },
        },
        key: "reject",
        name: "Rejected",
    },
    saveAsHealthInfo: {
        key: "saveAsHealthInfo",
        name: "Saved Health Info",
        value: 5
    },
    publish: {
        key: "publish",
        name: "Published",
        value: 3
    },
    republish: {
        key: "republish",
        name: "Re-published",
        value: 3
    },
}
