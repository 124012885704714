import React from "react";
import "./CustomPaginator.css";
import { Paginator } from 'primereact/paginator';
import { fontSize } from "../../../constants/styled";
import useReduxSearch from "../../../hooks/useReduxSearch";
import { routes } from "../../../constants/routings";
import { useDispatch } from "react-redux";
import { paginationActions } from "../../../store/paginationSlices";
import { getEventCards } from "../../../store/eventActions";

const CustomPaginator = ({ currentPath }) => {
    const paginator = useReduxSearch(routes.pagination.key)
    const selectedFilterData = useReduxSearch(routes.selectedFilters.key)
    const authToken = useReduxSearch(routes.auth.key).authToken
    const [pagination, setPagination] = React.useState({ currentPage: 0, totalPage: 0 })
    const dispatch = useDispatch()
    const rows = 1;
    const onPageChange = (e) => {
        if (e.first + 1 === pagination.currentPage) return
        const updatedPage = e.first + 1
        setPagination((prev) => ({ ...prev, currentPage: updatedPage }))
        dispatch(paginationActions.setPage({ key: currentPath, currentPage: updatedPage }));
        const params = {
            eventType: currentPath,
            fromDate: selectedFilterData.fromDate,
            toDate: selectedFilterData.toDate,
            disease: selectedFilterData.disease,
            state: selectedFilterData.state,
            district: selectedFilterData.district,
            currentPage: updatedPage,
        }
        if (selectedFilterData.subTypes && selectedFilterData.subTypes.length) {
            params["subTypes"] = selectedFilterData.subTypes
        }
        dispatch(getEventCards(params, authToken))
    };
    React.useEffect(() => {
        if (currentPath) {
            const pageObj = paginator[currentPath]
            setPagination(pageObj)
        }
    }, [currentPath, paginator]);
    const template3 = {
        layout: 'PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            const currentPage = Math.floor(options.first / rows);
            const totalPages = Math.ceil(options.totalRecords / rows);
            return (
                <span className={`text-color w-[40px] lg:w-[80px] xl:w-[100px] flex justify-end font-[400] ${fontSize[14]}`}>
                    Page {currentPage} of {totalPages}
                </span>
            );
        }
    };
    return (
        <div className="custom-paginator">
            <Paginator
                template={template3}
                first={pagination.currentPage - 1}
                totalRecords={pagination.totalPage}
                rows={rows}
                onPageChange={onPageChange}
                className={`justify-between bg-transparent text-black font-[500] mr-2 space-x-1 ${fontSize[12]}`}
            />
        </div>
    );
};

export default CustomPaginator;
