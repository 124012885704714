import { createSlice } from '@reduxjs/toolkit';
import { routes } from '../constants/routings';

const initialState = {
    open: false,
    message: null,
};

const notificationSlice = createSlice({
    name: routes.notification.reduxKey,
    initialState,
    reducers: {
        setNoification: (state, actions) => {
            state.open = actions?.payload?.open || false;
            state.message = actions?.payload?.message || null;
        },
        resetNotification: (state) => {
            state.open = false;
            state.message = null;
        }
    }
});

export const notificationActions = notificationSlice.actions;

export default notificationSlice.reducer;
