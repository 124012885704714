import React from "react";
import "./CustomDialogBox.css";
import { Dialog } from 'primereact/dialog';

const CustomDialogBox = ({ showDialog, classes, header, children, clickOutsideClose, setShowDialog = () => { } }) => {
    const headerElement = (
        <div className={`flex items-center space-x-2 rounded-t-[8px] px-5 py-4 ${header?.classes}`}>
            {header && header?.img && <img className="h-[1.8rem]" src={header?.img} alt={header?.img} />}
            {header && header?.text && <div className="font-bold">
                {header?.text}
                {header?.extra && header?.extra?.text && <span className={`${header?.extra?.classes}`}>{header?.extra?.text}</span>}
            </div>}
        </div>
    );
    return (
        <Dialog
            className={`${classes} rounded-[12px] bg-white`}
            visible={showDialog}
            header={header && headerElement}
            dismissableMask={clickOutsideClose}
            onHide={setShowDialog}
            draggable={false}
        >
            {children}
        </Dialog>
    )
}

export default CustomDialogBox;
