import { createSlice } from '@reduxjs/toolkit';
import { routes } from '../constants/routings';

const initialAuthState = {
    eventCounts: {
        extracted: {
            isFetching: false,
            count: 0,
        },
        shortlisted: {
            isFetching: false,
            count: 0,
        },
        rejected: {
            isFetching: false,
            count: 0,
        },
        healthInfo: {
            isFetching: false,
            count: 0,
        },
        published: {
            isFetching: false,
            count: 0,
        },
    },
    extracted: {
        isFetching: false,
        events: []
    },
    shortlisted: {
        isFetching: false,
        events: []
    },
    rejected: {
        isFetching: false,
        events: []
    },
    healthInfo: {
        isFetching: false,
        events: []
    },
    published: {
        isFetching: false,
        events: []
    }
};

const setEventsState = (state, eventType, payload) => {
    state[eventType].isFetching = payload.isFetching !== undefined ? payload.isFetching : state[eventType].isFetching;
    state[eventType].events = payload.events || state[eventType].events;
};

const setEventCountsState = (state, eventType, payload) => {
    state.eventCounts[eventType].isFetching = payload.isFetching !== undefined ? payload.isFetching : state.eventCounts[eventType].isFetching;
    state.eventCounts[eventType].count = payload.count >= 0 ? payload.count : state.eventCounts[eventType].count;
};

const eventsSlice = createSlice({
    name: routes?.events?.reduxKey,
    initialState: initialAuthState,
    reducers: {
        setCounts (state, actions) {
            const { eventType, payload } = actions.payload;
            setEventCountsState(state, eventType, payload);
        },
        setEvents (state, actions) {
            const { eventType, payload } = actions.payload;
            setEventsState(state, eventType, payload);
        }
    }
});

export const eventsActions = eventsSlice.actions;

export default eventsSlice.reducer;
