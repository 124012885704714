import React from "react";
import "./Filter.css";
import moment from "moment";
import { fontSize } from "../../constants/styled";
import FilterDropdown from "./Components/FilterDropdown";
import useReduxSearch from "../../hooks/useReduxSearch";
import { routes } from "../../constants/routings";
import CustomButton from "../shared/CustomButton/CustomButton";

const Filters = () => {
    const menuRef = React.useRef(null);
    const [showFilter, setShowFilter] = React.useState(false)
    const filterFields = ["fromDate", "fromTime", "toDate", "toTime", "disease", "state", "district"]
    const [changedFields, setChangedFields] = React.useState({
        isChanged: false,
        fields: filterFields.reduce((acc, field) => {
            acc[field] = false;
            return acc;
        }, {})
    });
    const selectedFilterData = useReduxSearch(routes.selectedFilters.key)

    const handleCloseFilter = () => {
        setShowFilter(false)
    }
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowFilter(false)
            if (!changedFields.isChanged) {
                setChangedFields({
                    isChanged: false,
                    fields: filterFields.reduce((acc, field) => {
                        acc[field] = false;
                        return acc;
                    }, {})
                })
            }
        }
    }
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
    return (
        <div className="filter-container w-[100%] h-[100%] flex-row justify-between">
            <div
                className="w-[100%] h-[100%] relative left-5"
            >
                <div className={`flex w-[100%] h-[100%] text-gray rounded-[15px] border-[1.3px] hover:cursor-pointer ${fontSize[16]} ${showFilter ? "bg-white" : "bg-blue-light hover:border-[#B5DAEA] hover:border-[1.3px] text-[#413F40]"} ${changedFields.isChanged ? "bg-white border-white" : "border-blue-light"}`} onClick={() => setShowFilter(!showFilter)}>
                    <div className={`w-[100%] h-[100%] flex space-x-3 items-center justify-between px-2 ${changedFields.isChanged ? "text-black" : "text-gray hover:text-[#413F40]"}`}>
                        {!showFilter && <div className="flex items-center justify-between w-full">
                            <div className={`flex h-[100%] py-1 items-center space-x-2 font-[600] ${fontSize[14]}`}>
                                <div className={`flex items-center space-x-2 h-[100%] px-2 bg-[#D7F1FD] rounded-[8px] ${fontSize[14]}`}>
                                    <div className={`text-blue`} >
                                        <i className={`pi pi-filter-fill ${fontSize[12]}`}/>
                                    </div>
                                    <div className="text-black font-[500] py-1">
                                        Filter
                                    </div>
                                </div>
                                <div className="flex space-x-2 items-center">
                                    <div className="flex items-center space-x-1"><i className={`pi pi-calendar font-[400] ${fontSize[14]}`} /> <span>Date:</span></div>
                                    <span className={`font-[500] ${fontSize[14]}`}>{moment(selectedFilterData.fromDate).format("Do MMMM, YYYY")} - {moment(selectedFilterData.toDate).format("Do MMMM, YYYY")}</span>
                                </div>
                            </div>
                            {changedFields.isChanged && <div className="font-[400]">
                                <CustomButton
                                    text={{ text: "View applied filters", class: `${fontSize[12]} text-[#115D7F]` }}
                                    background="px-3 py-1 bg-[#E8F0F3]"
                                    border="rounded-[8px]"
                                    hover="hover:cursor-pointer"
                                    action={() => setShowFilter(true)}
                                />
                            </div>}
                        </div>}
                        {showFilter && <div className={`w-[100%] h-[100%] flex items-center py-1 md:py-1 lg:py-2 xl:py-3 justify-between font-[600] ${fontSize[14]} font-[500]`}>
                            <div className={`flex items-center space-x-2 px-2 text-blue`}>
                                <div >
                                    <i className={`pi pi-filter-fill ${fontSize[12]}`}/>
                                </div>
                                <div className={`${fontSize[14]}`}>
                                    Detailed Filter
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                {!showFilter && <div className={`text-gray font-[400] ${fontSize[12]}`}>Time filter applied: {moment(selectedFilterData.fromDate).format('hh:mma')} to {moment(selectedFilterData.toDate).format('hh:mma')}</div>}
                {showFilter && <div className="mt-1 hover:cursor-default" ref={menuRef}>
                    <FilterDropdown selectedFilterData={selectedFilterData} filterFields={filterFields} changedFields={changedFields} setChangedFields={setChangedFields} closeFilter={handleCloseFilter} />
                </div>}
            </div>
        </div>
    );
};

export default Filters;
