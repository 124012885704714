import React from "react";
import { MultiSelect } from 'primereact/multiselect';
import "./CustomMultiSelect.css";

const CustomMultiSelect = ({ placeholder, icon, options, selectedOptions, filter, showClear, selectAllPlaceholder, maxSelectedLabels, classes, handleSelection = () => { } }) => {
    function getSelectedItemLabel () {
        const isContainEmptyValue = selectedOptions.indexOf("") !== -1;
        const finalSelectedItemLabel = (isContainEmptyValue ? selectedOptions.length - 1 : selectedOptions.length) + " " + selectAllPlaceholder + " selected";
        return finalSelectedItemLabel;
    }
    return (
        <div className={`w-full  border-1 border-white custom-group-multiselect ${icon && "flex rounded-s-[8px] items-center bg-white pl-2"}`}>
           {icon && <div className="">
                <img src={icon} className="h-[1.2rem]" alt="icon" />
            </div>}
            <MultiSelect
                value={selectedOptions}
                onChange={handleSelection}
                options={options}
                filter={filter}
                showClear={showClear}
                optionLabel="name"
                placeholder={placeholder}
                appendTo="self"
                selectAllLabel="Select All"
                maxSelectedLabels={maxSelectedLabels || 2}
                className={classes || "w-full rounded-[8px]"}
                selectedItemsLabel={getSelectedItemLabel()}
            />
        </div>
    );
};

export default CustomMultiSelect;
