import React from "react";
import "./CustomDatePicker.css";
import 'rsuite/dist/rsuite-no-reset.min.css';
import icon from '../../../resources/dateIcon.png';
import iconHighlight from '../../../resources/dateHighlightedIcon.png';
import { DatePicker } from 'rsuite';

const CustomDatePicker = ({ date, minDate, type, maxDate, highlight, classes, setDate = () => { } }) => {
  const handleDateChange = (date) => {
    setDate(date, type);
  };

  const disabledDate = (date) => {
    return date < minDate || date > maxDate;
  };
    const getContainer = () => {
    if (type === "fromDate") {
      return document.querySelector('.rs-picker-fromdate');
    } else if (type === "toDate") {
      return document.querySelector('.rs-picker-todate');
    }
    return null;
  };
  const pickerClass = type === "fromDate" ? "rs-picker-fromdate" : "rs-picker-todate";
  return (
    <div style={{ position: 'relative', width: "100%" }} className={`w-full  border-1 border-white rounded-[8px] custom-group-multiselect ${icon && "flex items-center bg-white"}`}>
      <div className="multiselect-icon-wrapper" style={{ zIndex: 6 }}>
        <img src={highlight ? iconHighlight : icon} className="multiselect-icon" alt="icon" />
      </div>
      <DatePicker
        value={date}
        onChange={handleDateChange}
        format="dd-MM-yyyy"
        placeholder="Select a date"
        disabledDate={disabledDate}
        block
        oneTap
        cleanable={false}
        showMeridian
         container={getContainer}
        editable={true}
        className={`${classes} ${pickerClass}`}
      />
    </div>
  )
}

export default CustomDatePicker;
