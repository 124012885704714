import React from "react";
import { NavLink } from "react-router-dom";
import "./LayoutCommon.css";
import { Sidebar } from 'primereact/sidebar';
import useScreenSize from "../hooks/useScreenSize";
import { routes } from "../constants/routings";
import { fontSize, headerHeight } from "../constants/styled";
import CustomButton from "../components/shared/CustomButton/CustomButton";
import { useDispatch } from "react-redux";
import { filterActions } from "../store/filterSlices";
import useReduxSearch from "../hooks/useReduxSearch";
import { getEventCards } from "../store/eventActions";
import { paginationActions } from "../store/paginationSlices";
import rejectIcon from "../resources/rejectIcon.png";
import rejectFilledIcon from "../resources/rejectFilledIcon.png";
import shortlistIcon from "../resources/shortlistIcon.png";
import shortlistFilledIcon from "../resources/shortlistFilledIcon.png";
import healthInfoIcon from "../resources/healthInfoIcon.png";
import healthInfoFilledIcon from "../resources/healthInfoFilledIcon.png";
import publishedIcion from "../resources/publishedIcon.png";
import publishedFilledIcion from "../resources/publishedFilledIcon.png";

const Template = ({ route, name, icon, iconType, count }) => {
    return (
        <NavLink to={route}
            className={({ isActive, isPending, isTransitioning }) => isActive ? "text-blue font-bold bg-blue-light" : "text-text-color bg-white"
        }>
            <div className="pr-4" style={{ background: "inherit" }}>
                <div className={`flex items-center space-x-3 bg-inherit pl-[2vw] xl:pl-[2rem] py-[0.5rem] lg:py-[0.7rem] border-b-[1px] border-[#D9DFE2] ${fontSize[16]}`}>
                    {iconType === "img" ? <img src={icon} alt={icon} className="h-[1.8vw] lg:h-[1.2rem] xl:h-[1.3rem]" /> : <i className={`pi ${icon}`}></i>}
                    <p>{name}</p>
                    {count.isFetching
                        ? <i className={`pi pi-spin pi-spinner ${fontSize[16]}`}></i>
                        : <p>({formatCount(count.count)})</p>}
                </div>
            </div>
        </NavLink>
    );
};

const formatCount = (count) => {
    return count > 999 ? (count / 1000).toFixed(2) + "K" : count;
}

const RoutesComp = ({ handleChangeCurrentPath = () => { } }) => {
    const dispatch = useDispatch()
    const counts = useReduxSearch(routes.eventCounts.key);
    const authToken = useReduxSearch(routes.auth.key).authToken;
    const selectedFilters = useReduxSearch(routes.selectedFilters.key);
    const handleNavigation = (key) => {
        dispatch(filterActions.setSelectedFilters({ eventType: key }))
        const currentPage = 1
        const params = {
            fromDate: selectedFilters.fromDate,
            toDate: selectedFilters.toDate,
            eventType: key,
            disease: selectedFilters.disease,
            state: selectedFilters.state,
            district: selectedFilters.district,
            currentPage
        }
        dispatch(getEventCards(params, authToken))
        handleChangeCurrentPath(key)
        dispatch(paginationActions.setPage({ key, currentPage }))
    }
    return (
        <div className="flex-row space-y-1 bg-white">
            <div className="m-0" onClick={() => handleNavigation(routes.extracted.key)}>
                <NavLink to={routes.extracted.route}
                    className={({ isActive, isPending, isTransitioning }) => isActive ? "text-blue bg-blue-light border-text-color" : "border-text-color"}
                >
                    <div className={`flex items-center justify-between  py-[0.5rem] lg:py-[0.7rem] pl-[2vw] pr-[10%] xl:pl-[2rem] border-t-[1px] border-b-[1px] border-[#D9DFE2] ${fontSize[18]}`} style={{ background: "inherit" }}>
                        <p className="font-bold">{routes.extracted.name}</p>
                        {counts[routes.extracted.key].isFetching
                            ? <i className={`pi pi-spin pi-spinner ${fontSize[18]}`}></i>
                            : <p className="text-text-color font-[400]">{formatCount(counts[routes.extracted.key].count)}</p>}
                    </div>
                </NavLink>
            </div>
            <div className="m-0" onClick={() => handleNavigation(routes.shortlisted.key)}>
                <Template route={routes.shortlisted.route} name={routes.shortlisted.name} icon={routes.shortlisted.key === selectedFilters.eventType ? shortlistFilledIcon : shortlistIcon} iconType="img" count={counts[routes.shortlisted.key]} />
            </div>
               <div className="m-0" onClick={() => handleNavigation(routes.published.key)}>
                <Template route={routes.published.route} name={routes.published.name} icon={routes.published.key === selectedFilters.eventType ? publishedFilledIcion : publishedIcion} iconType="img" count={counts[routes.published.key]} />
            </div>
            <div className="m-0" onClick={() => handleNavigation(routes.healthInfo.key)}>
                <Template route={routes.healthInfo.route} name={routes.healthInfo.name} icon={routes.healthInfo.key === selectedFilters.eventType ? healthInfoFilledIcon : healthInfoIcon} iconType="img" count={counts[routes.healthInfo.key]} />
            </div>
            <div className="m-0" onClick={() => handleNavigation(routes.rejected.key)}>
                <Template route={routes.rejected.route} name={routes.rejected.name} icon={routes.rejected.key === selectedFilters.eventType ? rejectFilledIcon : rejectIcon} iconType="img" count={counts[routes.rejected.key]} />
            </div>
        </div>
    );
};

const SmallScreenComp = ({ showNav, setShowNav, handleChangeCurrentPath = () => { } }) => {
    return (
        <div>
            <CollapseOnComp setShowNav={setShowNav} />
            <Sidebar visible={showNav} blockScroll={true} showCloseIcon={false} onHide={() => setShowNav(false)} className="fixed w-[15rem] bg-white">
                <HeaderComp setShowNav={setShowNav} />
                <RoutesComp handleChangeCurrentPath={handleChangeCurrentPath} />
            </Sidebar>
        </div>
    );
};

const LargeScreenComp = ({ setShowNav, handleChangeCurrentPath = () => { } }) => {
    return (
        <div>
            <HeaderComp setShowNav={setShowNav} />
            <RoutesComp handleChangeCurrentPath={handleChangeCurrentPath} />
        </div>
    );
};

const CollapseOnComp = ({ setShowNav, handleChangeCurrentPath = () => { } }) => {
    const [hoverState, setHoverState] = React.useState(false)
    return (
        <div className={`${fontSize[18]} w-[100%] h-[100%] flex`}>
            <div className={`w-[50%] items-self-start pt-3 flex py-2 justify-center br-[50%] ${hoverState && "bg-white"}`} onClick={() => setShowNav(true)}>
                <div className="w-[content-width] mt-2">
                    <CustomButton
                        fontSize={fontSize[16]}
                        icon={{ pre: true, class: `${hoverState ? "pi-chevron-right" : "pi-align-justify"}` }}
                        border="rounded-[50%]"
                        background="hover:cursor-pointer hover:bg-blue-light p-2"
                        onMouseEnter={() => setHoverState(true)} onMouseLeave={() => setHoverState(false)}
                    />
                </div>
            </div>
            <div></div>
        </div>
    );
};

const HeaderComp = ({ setShowNav }) => {
    return (
        <div className={`bg-white flex items-start justify-end space-x-2 font-[600] ${headerHeight} ${fontSize[18]}`}>
            <div className="flex items-center justify-between space-x-5 pt-3 pr-[2rem] lg:pr-[2rem] xl:pr-[2.5rem] md:pt-4 xl:justify-end">
                <p>MEDIA ALERTS</p>
                <div>
                    <CustomButton
                        fontSize={fontSize[16]}
                        icon={{ pre: true, class: "pi-chevron-left" }}
                        background="p-2 bg-[#F2F7FA]"
                        border="rounded-[50%]"
                        hover="hover:cursor-pointer hover:bg-blue-light"
                        action={() => setShowNav(false)}
                    />
                </div>
            </div>
        </div>
    );
};

const CollapseOffComp = ({ showNav, isMobile, setShowNav, handleChangeCurrentPath = () => { } }) => {
    return (
        <div>
            {isMobile ? <SmallScreenComp showNav={showNav} setShowNav={setShowNav} handleChangeCurrentPath={handleChangeCurrentPath} /> : <LargeScreenComp setShowNav={setShowNav} handleChangeCurrentPath={handleChangeCurrentPath} />}
        </div>
    );
};

const SideNav = ({ showNav, setShowNav, handleChangeCurrentPath = () => { } }) => {
    const isMobile = useScreenSize();
    return (
        <div className={`h-[100vh] w-full ${showNav ? "bg-white" : "bg-transparent"}`}>
            {showNav ? <CollapseOffComp showNav={showNav} isMobile={isMobile} setShowNav={setShowNav} handleChangeCurrentPath={handleChangeCurrentPath} /> : <CollapseOnComp setShowNav={setShowNav} />}
        </div>
    );
};

export default SideNav;
