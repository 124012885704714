import React from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import Routings from "./Routings";
import CustomPaginator from "../components/shared/CustomPaginator/CustomPaginator";
import useReduxSearch from "../hooks/useReduxSearch";
import useNetworkStatus from "../hooks/useNetworkStatus";
import OfflinePage from "../pages/offlinePage/OfflinePage"
import { routes } from "../constants/routings";
import { getFilterData } from "../store/filterActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postSaveAllFilterAction } from "../store/eventActions";
import CustomToast from "../components/shared/CustomToast/CustomToast";

const Main = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [showSideNav, setShowSideNav] = React.useState(true);
    const isOnline = useNetworkStatus()
    const auth = useReduxSearch(routes.auth.key);
    const [currentPath, setCurrentPath] = React.useState(routes.extracted.key)
    const handleChangeCurrentPath = (newPath) => {
        setCurrentPath(newPath)
    }
    const isRequiredFiltersFetched = useSelector(state => state[routes.filters.reduxKey].isFetchedRequiredData);
    const selectedFilters = useReduxSearch(routes.selectedFilters.key)
    React.useEffect(() => {
        if (auth.isAuthenticated && auth.authToken) {
            dispatch(getFilterData(auth.authToken));
            navigate(routes.extracted.route)
        }
    }, [auth.isAuthenticated, auth.authToken, dispatch]);
    React.useEffect(() => {
        if (auth.isAuthenticated && auth.authToken && isRequiredFiltersFetched) {
            const eventTypes = [
                routes.extracted.eventType,
                routes.rejected.eventType,
                routes.shortlisted.eventType,
                routes.healthInfo.eventType,
                routes.published.eventType,
            ]
            const params = {
                eventType: routes.extracted.key,
                fromDate: selectedFilters.fromDate,
                toDate: selectedFilters.toDate,
                disease: selectedFilters.disease,
                state: selectedFilters.state,
                district: selectedFilters.district,
                currentPage: 1,
            }
            dispatch(postSaveAllFilterAction(params, eventTypes, auth.authToken))
        }
    }, [auth.isAuthenticated, auth.authToken, isRequiredFiltersFetched, dispatch]);
    if (!isOnline) return <OfflinePage />
    if (!auth.isAuthenticated) return <div>Login Failed</div>
    return (
        <div className="w-full">
            <div className={`flex h-screen w-full`}>
                <div className={`z-2 ${showSideNav ? "w-[20rem] sm:w-[14rem] md:w-[15rem] lg:w-[16rem] xl:w-[20rem] 2xl:w-[22rem]" : styledSideGaps.width} flex-shrink-0`}>
                    <SideNav showNav={showSideNav} setShowNav={setShowSideNav} handleChangeCurrentPath={handleChangeCurrentPath} />
                </div>
                <div className={`w-full overflow-auto ${showSideNav ? styledSideGaps.openPadding : styledSideGaps.closePadding}`}>
                    <div className={`w-6/6`}>
                        <div className={`sticky top-0 bg-[#F0F4F7] z-1`}>
                            <Header currentPath={currentPath}/>
                        </div>
                        <div className="mb-[8rem]">
                            <Routings />
                        </div>
                        <div className={`fixed right-0 bottom-0 bg-[#F0F4F7] z-1 w-full ${showSideNav ? styledSideGaps.openPadding : styledSideGaps.closePadding}`}>
                            <div className="flex justify-end">
                                {currentPath && <CustomPaginator currentPath={currentPath} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomToast />
        </div>
    );
};

const styledSideGaps = {
    width: "w-[3.5rem] md:w-[5rem] lg:w-[6rem] xl:w-[7rem]",
    closePadding: "pr-[4rem] md:pr-[5rem] lg:pr-[6rem] xl:pr-[7rem]",
    openPadding: "pr-[1rem] md:pr-[2rem] lg:pr-[3rem] xl:pr-[4rem]"
}

export default Main;
