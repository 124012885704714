import * as React from 'react';
import "./RejectButton.css";
import { actionTypeMap } from '../../../constants/constants';
import { fontSize } from '../../../constants/styled';

const RejectButton = ({ handleAction = () => { } }) => {
    const [showFlag, setShowFlag] = React.useState(false)
    const menuRef = React.useRef(null);
    const options = [
        {
            name: actionTypeMap.reject.types.rejectFakeSource.name,
            value: actionTypeMap.reject.types.rejectFakeSource.value
        },
        {
            name: actionTypeMap.reject.types.rejectOldNews.name,
            value: actionTypeMap.reject.types.rejectOldNews.value
        },
        {
            name: actionTypeMap.reject.types.rejectIrrelevant.name,
            value: actionTypeMap.reject.types.rejectIrrelevant.value
        },
        {
            name: actionTypeMap.reject.types.rejectPublished.name,
            value: actionTypeMap.reject.types.rejectPublished.value
        },
        {
            name: actionTypeMap.reject.types.rejectInternationalEvent.name,
            value: actionTypeMap.reject.types.rejectInternationalEvent.value
        },
    ]

    function handleClickOutside (event) {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowFlag(false)
        }
    }

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleShowDropdown = () => {
        setShowFlag(!showFlag)
    }

    return (
        <div className='main-div' ref={menuRef}>
            {showFlag && <div className="reject-options-div">
                {options.map((option, idx) =>
                        <div key={idx} className="reject-option-label" onClick={() => handleAction(actionTypeMap.reject.key, option)}>
                            <div className={`w-[120px] font-500] text-[#74082B] ${fontSize[12]}`}>{option.name}</div>
                            <i className="pi pi-check"></i>
                        </div>
                    )
                }
            </div>}
            <div className={`flex rounded-[8px] border-1 border-[#D7686D] text-[#D7686D] space-x-2 px-2 xl:px-3 py-1 lg:py-2 whitespace-nowrap bg-white font-[500] ${fontSize[14]} hover:cursor-pointer`} onClick={handleShowDropdown}>
                <p>Reject</p>
                <div><i className={`pi ${showFlag ? "pi-angle-up" : "pi-angle-down"}`} /> </div>
            </div>
        </div>
    )
}

export default RejectButton;
