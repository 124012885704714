import { eventsActions } from "./eventSlices";
import { getEventCounts as getEventCountsApi, getEvents } from "../utils/apiCalls";
import { paginationActions } from "./paginationSlices";
import { itemsPerPage } from "../constants/constants";

export const postSaveAllFilterAction = (params, eventTypes, authToken) => {
    return async (dispatch) => {
        dispatch(getEventCards(params, authToken))
        eventTypes.forEach((eventType) => {
            const paramDic = { ...params, eventType };
            dispatch(getEventCount(paramDic, authToken, 'filter'));
        });
    }
}

export const postActionButtonClick = (params, eventTypes, authToken) => {
    return async (dispatch) => {
        dispatch(getEventCards(params, authToken))
        eventTypes.forEach((eventType) => {
            const paramDic = { ...params, eventType };
            dispatch(getEventCount(paramDic, authToken, "action"));
        });
    }
}

export const getEventCount = (params, authToken, actionType) => {
    return async (dispatch) => {
        dispatch(eventsActions.setCounts({
            payload: { isFetching: true },
            eventType: params.eventType
        }))
        const count = await getEventCountsApi(params, authToken)
        dispatch(eventsActions.setCounts({
            payload: { isFetching: false, count },
            eventType: params.eventType
        }))
        if (actionType && actionType === "filter") {
            dispatch(paginationActions.setPage({
                key: params.eventType,
                currentPage: count > 0 ? 1 : 0,
                totalPage: Math.ceil((count / itemsPerPage) || 0)
            }))
        } else if (actionType && actionType === "action") {
            dispatch(paginationActions.setPage({
                key: params.eventType,
                totalPage: Math.ceil((count / itemsPerPage) || 0)
            }))
        }
    }
}

export const getEventCards = (params, authToken) => {
    return async (dispatch) => {
        dispatch(eventsActions.setEvents({
            eventType: params.eventType,
            payload: { isFetching: true },
        }))
        const events = await getEvents(params, authToken)
        dispatch(eventsActions.setEvents({
            eventType: params.eventType,
            payload: { isFetching: false, events },
        }))
    }
}
