import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import '../CustomGroupSelect/CustomGroupSelect.css';
import { fontSize } from '../../../constants/styled';

const CustomGroupSelect = ({ placeholder, selectedOption, options, icon, filter, showClear, classes, panelClasses, disabled, handleChange = () => { } }) => {
    function findLabelName (data, value) {
        return data.find(item => item.items.find(item => item.value === value))?.label;
    }

    const handleSelectedChange = (e) => {
        const label = findLabelName(options, e.value)
        handleChange(e.value, label)
    };

    const selectedOptionTemplate = (option, icon, placeholder) => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                {icon && <img src={icon} className="ml-1 h-[0.7rem] md:h-[0.9rem] lg:h-[1rem] xl:h-[1.2rem]" alt="icon" />}
                {option && <div className={`ml-3 ${fontSize[12]}`}>{option}</div>}
                {!option && placeholder && <span className={`ml-3 ${fontSize[12]}`}>{placeholder}</span>}
            </div>
        );
    };
    return (
        <div>
            <Dropdown
                className={classes}
                panelClassName={panelClasses}
                value={selectedOption}
                options={options}
                onChange={handleSelectedChange}
                optionLabel="name"
                optionGroupLabel="label"
                optionGroupChildren="items"
                placeholder={placeholder}
                appendTo="self"
                filter={filter}
                showFilterClear
                icon={icon}
                disabled={disabled}
                showClear={showClear && selectedOption}
                valueTemplate={() => selectedOptionTemplate(selectedOption, icon, placeholder)}

            />
        </div>
        );
};

export default CustomGroupSelect;
